import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
//MATERIAL-UI
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';

import { IServicio } from '../../../Interfaces/IServicio';
import imgCamDoctor1 from '../../../assets/images/imgCamDoctor1.png'
import { WorkOutlineOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { estaLibreParaInstantaneas } from '../../../apis/citaInstantaneaAPI';
import { useTime } from '../../../hooks/useTime';

const WelcomeMedico = () => {
    const history = useHistory()

    const { especialidades, email } = useSelector(
        (state: RootState) => state.user
    )

    const [libreParaInstantaneas, setLibreParaInstantaneas] = useState(false);

    const now = useTime(60000);

    useEffect(() => {
        estaLibreParaInstantaneas(email)
            .then(
                (datosRecibidos) => {
                    setLibreParaInstantaneas(datosRecibidos);
                }
            )
            .catch(e => { console.log(e); })
    }, [now, email])

    const onItemClicked = (servicio: IServicio) => {
        localStorage.setItem("LastItemClicked", servicio.id);
        history.push(servicio.to)
    }

    let atiendeGuardia = false;
    especialidades!.forEach((especialidad) => {
        if (especialidad.consultaEspontanea === 1) {
            atiendeGuardia = true;
        }
    });

    let atiendeProgramadas = false
    especialidades!.forEach((especialidad) => {
        if (especialidad.consultaProgramada === 1) {
            atiendeProgramadas = true;
        }
    });

    const serviciosGuardia: ReadonlyArray<IServicio> = [
        {
            id: 'ATENDER_CONSULTAS_GUARDIA',
            primaryText: "Atender videoconsultas de guardia",
            secondaryText: "Consultorio de demanda espontánea",
            icon: VideocamOutlinedIcon,
            to: "/dashboard/guardia",
        },
    ]

    const serviciosProgramadas: ReadonlyArray<IServicio> = [
        {
            id: 'ATENDER_CONSULTORIO_PROGRAMADAS',
            primaryText: "Atender en mi consultorio",
            secondaryText: "Consultorio de demanda programada. Turnos para hoy",
            icon: EventAvailableOutlinedIcon,
            to: "/dashboard/atender-consultorio",
        },
        {
            id: 'GESTIONAR_CONSULTORIO',
            primaryText: "Gestionar mi consultorio",
            secondaryText: "Manejar la modalidad de atención. Facturación Unificada",
            icon: WorkOutlineOutlined,
            to: "/dashboard/administrar-consultorio",
        }
    ]

    const serviciosGuardiaList = serviciosGuardia.map((servicio, index) => {
        return (
            <Box key={index} borderBottom="1px solid #efefef" >
                <ListItem button disabled={!libreParaInstantaneas} style={{ paddingTop: 20, paddingBottom: 20 }} onClick={() => onItemClicked(servicio)} >
                    <ListItemIcon>
                        <servicio.icon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                        primary={servicio.primaryText}
                        secondary={servicio.secondaryText}
                    />
                    <ListItemSecondaryAction >
                        <IconButton edge="end" disabled={!libreParaInstantaneas} onClick={() => onItemClicked(servicio)}   >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </Box>
        )
    })
    const serviciosProgramadasList = serviciosProgramadas.map((servicio, index) => {
        return (
            <Box key={index} borderBottom="1px solid #efefef" >
                <ListItem button style={{ paddingTop: 20, paddingBottom: 20 }} onClick={() => onItemClicked(servicio)} >
                    <ListItemIcon>
                        <servicio.icon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                        primary={servicio.primaryText}
                        secondary={servicio.secondaryText}
                    />
                    <ListItemSecondaryAction onClick={() => onItemClicked(servicio)} >
                        <IconButton edge="end" >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </Box>
        )
    })

    return (
        <Grid container justify="center" spacing={3}>
            <Grid item xs={12} md={7} lg={7}>
                <List>
                    {
                        atiendeGuardia && serviciosGuardiaList
                    }
                    {
                        atiendeProgramadas && serviciosProgramadasList
                    }
                </List>
            </Grid>
            <Grid item xs={10} md={5} lg={5}>
                <img src={imgCamDoctor1} alt="imagen app mobile" width="100%" />
            </Grid>
        </Grid>
    )
}

export default WelcomeMedico
