import React from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Box,
  Divider,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";

import {styles} from "./ConfirmarStyles";
import {
  setPaso,
  setIdSalaVideollamada,
  setPassSalaVideollamada,
  setTiempoEsperaPromedio,
} from "../../../../store/paciente/ver-medico-online/verMedicoOnlineSlice";
import {RootState} from "../../../../store/rootReducer";
import Volver from "../../../../components/buttons/Volver/Volver";
import BtnConfirmar from "../../../../components/buttons/BtnConfirmar/BtnConfirmar";
import PerfilPaciente from "../../../../components/PerfilPaciente/PerfilPaciente";
import {postCitaInstantanea} from "../../../../apis/citaInstantaneaAPI";
import {IPostCitaInstantanea} from "../../../../Interfaces/IPostCitaInstantanea";
import {v4 as uuidv4} from "uuid";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";

const Confirmar: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {cuilPacienteSeleccionado, pacientesByCuil} = useSelector(
    (state: RootState) => state.user
  );
  const {especialidad, sintomaGuardado} = useSelector(
    (state: RootState) => state.verMedicoOnline
  );
  const {email, ID_GRUPO_FAMILIAR} = useSelector(
    (state: RootState) => state.user
  );
  const [loading, setLoading] = React.useState(false);

  async function onConfirmarClicked() {
    const sesion = uuidv4();
    const bcrypt = require("bcryptjs");
    const saltRounds = 8;
    const password = bcrypt.hashSync(sesion, saltRounds);
    let datosCita: IPostCitaInstantanea = {
      usuario: cuilPacienteSeleccionado.toString(),
      sesion: sesion,
      password: password,
      especialidad: especialidad,
      motivo: sintomaGuardado ? sintomaGuardado!.sintoma : "",
      triaje: sintomaGuardado ? sintomaGuardado!.preguntas : [],
      emailUsuario: email,
      ID_GRUPO_FAMILIAR: ID_GRUPO_FAMILIAR,
      NRO_DOCUMENTO: pacientesByCuil[cuilPacienteSeleccionado]
        .NRO_DOCUMENTO as any,
    };

    dispatch(setPaso(5));
    
    dispatch(setPassSalaVideollamada(password));
    setLoading(true);
    postCitaInstantanea(datosCita)
      .then((datos) => {
        dispatch(setIdSalaVideollamada(datos.estado));
        dispatch(setTiempoEsperaPromedio(datos.tiempoEsperaPromedio));
        setLoading(false);
        history.push(`/dashboard/ver-medico-online/espera`);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <Typography variant="h6">
        Confirmación de la consulta de {especialidad}
      </Typography>
      <Divider style={{margin: "20px 0px 20px 0px"}} />
      <Box my={4}>
        <PerfilPaciente paciente={pacientesByCuil[cuilPacienteSeleccionado]} />
      </Box>
      <Divider />
      {loading ? (
        <LoadingBubbles />
      ) : (
        <div className={classes.buttonsRoot}>
          <Volver onClickHandler={() => history.goBack()} />
          <BtnConfirmar
            onClickHandler={() => {
              onConfirmarClicked();
            }}
          />
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(Confirmar);
