import React, {useState} from "react";
import {
  Divider,
  WithStyles,
  withStyles,
  useTheme,
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Chip,
  ListItemSecondaryAction,
  Paper,
  CardActionArea,
  CardMedia,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  FormControl,
  InputLabel,
  Stepper,
  Step,
  StepButton,
  Toolbar,
  StepConnector,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {styles} from "./NuevoTurnoStyles";
import {AddOutlined} from "@material-ui/icons";
import Volver from "../../../../components/buttons/Volver/Volver";
import {useHistory} from "react-router";
import {IServicio} from "../../../../Interfaces/IServicio";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SeleccionFinanciador from "./Pasos/SeleccionFinanciador";
import SeleccionProfesional from "./Pasos/SeleccionProfesional";
import {IProfesionalTurnos} from "../../../../Interfaces/IProfesionalHorario";
import DatosPaciente from "./Pasos/DatosPaciente";
import SeleccionEspecialidad from "./Pasos/SeleccionEspecialidad";
import {IFinanciador} from "../../../../Interfaces/IFinanciador";
import {IEspecialidad} from "../../../../Interfaces/IEspecialidad";
import {
  crearCitaProgramadaFinochietto,
  devolverDatosPaciente,
  especialidadesFinochietto,
  obtenerFinanciadores,
  obtenerListaProfesionalesFinochietto,
} from "../../../../apis/finochiettoAPI";
import {ICrearCitaProgramadaFinochietto} from "../../../../Interfaces/ICrearCitaProgramadaFinochietto";
import {
  convertDatestringToDate,
  convertDateToDatestring,
  convertDateToTimestring,
} from "../../../../utils/dateTimeHelper";
import {v4 as uuidv4} from "uuid";
import TurnoConfirmado from "./Pasos/TurnoConfirmado";
import {
  cargarSobreturno,
  getDiasLibres,
  getInfoDia,
} from "../../../../apis/citaProgramadaAPI";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/rootReducer";
import {useLocalStorage} from "../../../../hooks/useLocalStorage";
import {IProfesional} from "../../../../Interfaces/IProfesional";

const NuevoTurno: React.FC<WithStyles<typeof styles>> = (props) => {
  const {classes} = props;
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const history = useHistory();

  const [paso, setPaso] = useState(0);

  const {
    NOMBRE: nombreOperador,
    APELLIDO: apellidoOperador,
    email: emailOperador,
  } = useSelector((state: RootState) => state.user);

  const onItemClicked = (servicio: IServicio) => {
    history.push(servicio.to);
  };

  const motivos = [
    "Consulta de seguimiento",
    "Consulta inicial",
    "Control",
    "Reconsulta",
    "Sin especificar / otros",
  ];

  const turnosHorarios = [
    {
      nombre: "MAÑANA",
      desde: 0,
      hasta: 12,
    },
    {
      nombre: "TARDE",
      desde: 12,
      hasta: 18,
    },
    {
      nombre: "NOCHE",
      desde: 18,
      hasta: 24,
    },
  ];

  const [financiador, setFinanciador] = useState<IFinanciador | undefined>(
    undefined
  );
  const [especialidad, setEspecialidad] = useState<IEspecialidad | undefined>(
    undefined
  );
  const [posiblesProfTurnos, setPosiblesProfTurnos] = useState<
    Array<IProfesionalTurnos>
  >([]);
  const [tabsTurnoSeleccionadas, setTabsTurnoSeleccionadas] = React.useState<
    Array<number>
  >([]);
  const [selectedProfTurno, setSelectedProfTurno] = useState<
    IProfesionalTurnos | undefined
  >();
  const [profSobreturno, setProfSobreturno] = useState<string | null>(null);
  const [horaSobreturno, setHoraSobreturno] = useState<Date | null>(null);
  const [listadoMedicos, setListadoMedicos] = React.useState<
    Array<IProfesional>
  >([]);
  const [loadingMedicos, setLoadingMedicos] = React.useState(false);

  const [fechasLibres, setFechasLibres] = useState<Array<string>>([]);
  const [selectedTurno, setSelectedTurno] = useState<string>("16:30");
  const [fecha, setFecha] = useState<Date | undefined>();
  const [profesional, setProfesional] = useState<string | undefined>(undefined);

  const [datosLocked, setDatosLocked] = useState(false);
  const [credencialLocked, setCredencialLocked] = useState(false);
  const [cuil, setCuil] = useState("");
  const [fechaNac, setFechaNac] = useState<Date | null>(null);
  const [genero, setGenero] = useState<"M" | "F" | "X" | undefined>();
  const [motivo, setMotivo] = useState<string>("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [email, setEmail] = useState("");
  const [numCredencial, setNumCredencial] = useState("");
  const [celular, setCelular] = useState("");

  const [loadFinanciadores, setLoadFinanciadores] = useState(false);
  const [loadEspecialidades, setLoadEspecialidades] = useState(false);
  const [loadFechas, setLoadFechas] = useState(false);
  const [loadTurnos, setLoadTurnos] = useState(false);
  const [puedeContinuar, setPuedeContinuar] = useState(false);

  const [financiadores, setFinanciadores] = useState<Array<IFinanciador>>([]);
  const [especialidades, setEspecialidades] = useState<Array<IEspecialidad>>(
    []
  );

  const [loadingConfirmar, setLoadingConfirmar] = useState(false);

  const [loadingBuscarCUIL, setLoadingBuscarCUIL] = useState(false);

  const [mensajeDialog, setMensajeDialog] = useState("");

  React.useEffect(() => {
    //cargar financiadores
    //solo cuando carga la pagina
    setLoadFinanciadores(true);
    obtenerFinanciadores()
      .then((resultado) => {
        //solo los activos
        setFinanciadores(resultado.filter((x) => x.activo === "S"));
        setLoadFinanciadores(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  React.useEffect(() => {
    //cargar especialidades
    //cuando cambia el financiador
    //solo si el financiador no es undefined

    if (financiador) {
      setLoadEspecialidades(true);
      especialidadesFinochietto()
        .then((resultado) => {
          //solo los activos
          let esps = resultado.filter((especialidad) => {
            let prestadorCorrecto = false;
            especialidad.tmPrestador!.forEach((prestador) => {
              if (
                (prestador.descripcion.toLowerCase() === "finochietto" || prestador.descripcion.toUpperCase() === "SANATORIO FINOCHIETTO") &&
                prestador.activo === "S"
              ) {
                prestadorCorrecto = true;
              }
            });
            return (
              prestadorCorrecto &&
              (especialidad.consultaEspontanea ||
                especialidad.consultaProgramada)
            );
          });
          setEspecialidades(esps);
          setLoadEspecialidades(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [financiador]);

  React.useEffect(() => {
    //cargar fechas libres
    //cuando cambia la especialidad
    //solo si la especialidad no es undefined
    console.log("0. empezo");
    if (especialidad) {
      console.log("1. especialidad es algo");
      setLoadFechas(true);
      getDiasLibres(especialidad.descripcion)
        .then((recibido) => {
          console.log("2. resultado getdiaslibres");
          if (recibido.length > 0) {
            recibido.sort((a, b) => {
              return (
                convertDatestringToDate(a).getTime() -
                convertDatestringToDate(b).getTime()
              );
            });
          }
          setFechasLibres(recibido);
          if (recibido.length > 0) {
            setFecha(convertDatestringToDate(recibido[0]));
          }
          setLoadFechas(false);
        })
        .catch((e) => console.log(e));
    }
  }, [especialidad]);

  React.useEffect(() => {
    //cargar turnos y medicos disponibles
    //cuando cambia la fecha
    //solo si esta seleccionada una fecha y una especialidad
    if (fecha && especialidad) {
      setLoadTurnos(true);
      setSelectedProfTurno(undefined);
      getInfoDia(especialidad!.descripcion, fecha!)
        .then((valoresRecibidos) => {
          valoresRecibidos = valoresRecibidos.filter(
            (x) => x.turnos.length > 0
          );
          setPosiblesProfTurnos(valoresRecibidos);

          const tabs: Array<number> = [];

          valoresRecibidos.forEach((profesionalTurno, iProfesionalTurno) => {
            const primerTurno = profesionalTurno!.turnos[0];
            const horaDelTurno = Number.parseInt(primerTurno!.split(":")[0]);

            turnosHorarios.forEach((turnoHorario, iTurnoHorario) => {
              if (
                horaDelTurno >= turnoHorario.desde &&
                horaDelTurno < turnoHorario.hasta
              ) {
                tabs.push(iTurnoHorario);
              }
            });
          });
          setTabsTurnoSeleccionadas(tabs);
          setLoadTurnos(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [fecha]);

  React.useEffect(() => {
    //obtener listado de medicos
    //solo cuando carga la pagina
    setLoadingMedicos(true);
    obtenerListaProfesionalesFinochietto()
      .then((datosObtenidos) => {
        const datosObtenidosSorted = datosObtenidos.sort((a, b) => {
          let configurarProgramadaA = false;
          a.especialidad!.forEach((especialidad) => {
            if (especialidad.consultaProgramada) configurarProgramadaA = true;
          });
          let configurarProgramadaB = false;
          b.especialidad!.forEach((especialidad) => {
            if (especialidad.consultaProgramada) configurarProgramadaB = true;
          });
          if (configurarProgramadaA !== configurarProgramadaB) {
            if (configurarProgramadaA) {
              return -1;
            } else {
              return 1;
            }
          } else {
            const [nombreMedicoA, matriculaA] = a.profesional.split(":");
            const [nombreMedicoB, matriculaB] = b.profesional.split(":");

            return nombreMedicoA.localeCompare(nombreMedicoB);
          }
        });
        setListadoMedicos(datosObtenidosSorted);
        setLoadingMedicos(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const onSiguienteClicked = () => {
    if (paso < pasos.length - 1) {
      setPuedeContinuar(false);
      setPaso(paso + 1);
    } else {
      setPaso(paso + 1);
      if (selectedProfTurno) {
        //en este caso, eligio un turno habilitado
        const datos: ICrearCitaProgramadaFinochietto = {
          paciente: {
            cuil: cuil,
            nombre: nombre,
            apellido: apellido,
            genero: genero!,
            numeroCredencial: numCredencial,
            fechaNacimiento: convertDateToDatestring(fechaNac!),
            celular: celular,
            email: email,
          },
          especialidad: especialidad!.descripcion,
          codigoEspecialidad: especialidad!.codigoEspecialidad,
          financiador: financiador!.id,
          motivo: motivo,
          profesional: selectedProfTurno!.profesional,
          turno: {
            día: convertDateToDatestring(fecha!),
            horas: selectedTurno,
          },
          sesion: uuidv4(),
          password: "",
          nombreOperador: nombreOperador,
          apellidoOperador: apellidoOperador,
          emailOperador: emailOperador,
        };
        setLoadingConfirmar(true);
        crearCitaProgramadaFinochietto(datos)
          .then((result) => {
            setLoadingConfirmar(false);
            if (result) {
              console.log("CONFIRMADO");
            } else {
              console.log("ERROR");
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        //en este caso, esta creando un sobreturno
        setLoadingConfirmar(true);
        cargarSobreturno(profSobreturno!, horaSobreturno!, fecha!)
          .then((respuesta) => {
            const datos: ICrearCitaProgramadaFinochietto = {
              paciente: {
                cuil: cuil,
                nombre: nombre,
                apellido: apellido,
                genero: genero!,
                numeroCredencial: numCredencial,
                fechaNacimiento: convertDateToDatestring(fechaNac!),
                celular: celular,
                email: email,
              },
              especialidad: especialidad!.descripcion,
              codigoEspecialidad: especialidad!.codigoEspecialidad,
              financiador: financiador!.id,
              motivo: motivo,
              profesional: profSobreturno!,
              turno: {
                día: convertDateToDatestring(fecha!),
                horas: convertDateToTimestring(horaSobreturno!),
              },
              sesion: uuidv4(),
              password: "",
              nombreOperador: nombreOperador,
              apellidoOperador: apellidoOperador,
              emailOperador: emailOperador,
            };
            if (respuesta) {
              crearCitaProgramadaFinochietto(datos)
                .then((result) => {
                  setLoadingConfirmar(false);
                  if (result) {
                    console.log("CONFIRMADO");
                  } else {
                    console.log("ERROR");
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          })
          .catch((e) => console.log(e));
      }
    }
  };

  React.useEffect(() => {
    if (
      numCredencial === "" &&
      financiador?.descripcion.toLowerCase() === "medife"
    ) {
      setCredencialLocked(false);
    } else if (financiador?.descripcion.toLowerCase() !== "medife") {
      setCredencialLocked(false);
    }
    // else if (numCredencial.length >= 11  && financiador?.descripcion.toLowerCase() === "medife"){
    //     setCredencialLocked(true);
    // }
  }, [numCredencial, financiador]);

  const error400 = useLocalStorage("Error400", 100);

  const buscarPorCUIL = (cuil: string) => {
    setLoadingBuscarCUIL(true);
    devolverDatosPaciente(cuil, financiador!.id)
      .then((respuesta) => {
        setLoadingBuscarCUIL(false);
        if (respuesta.encontrado) {
          setNombre(respuesta.paciente!.nombre);
          setApellido(respuesta.paciente!.apellido);
          setFechaNac(
            convertDatestringToDate(respuesta.paciente!.fechaNacimiento)
          );
          setCelular(respuesta.paciente!.celular);
          setNumCredencial(respuesta.paciente!.numeroCredencial);
          setGenero(respuesta.paciente!.genero as "M" | "F" | "X");
          setEmail(respuesta.paciente!.email);
          if (financiador!.descripcion.toLowerCase() === "medife") {
            setDatosLocked(true);
            if (respuesta.paciente!.numeroCredencial !== "") {
              setCredencialLocked(true);
            }
          }
        } else {
          setMensajeDialog(respuesta.mensaje!);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const pasos = [
    {
      titulo: "Seleccionar Financiador",
      content: (
        <SeleccionFinanciador
          financiadores={financiadores}
          onChangeFinanciador={setFinanciador}
          financiador={financiador}
          onHabilitarSiguiente={setPuedeContinuar}
          siguienteHabilitado={puedeContinuar}
          loading={loadFinanciadores}
        />
      ),
    },
    {
      titulo: "Seleccionar Especialidad",
      content: (
        <SeleccionEspecialidad
          especialidades={especialidades}
          onChangeEspecialidad={setEspecialidad}
          especialidad={especialidad}
          onHabilitarSiguiente={setPuedeContinuar}
          siguienteHabilitado={puedeContinuar}
          loading={loadEspecialidades}
        />
      ),
      onAtras: () => {
        setEspecialidad(undefined);
      },
    },
    {
      titulo: "Seleccionar Profesional",
      content: (
        <SeleccionProfesional
          onHabilitarSiguiente={setPuedeContinuar}
          siguienteHabilitado={puedeContinuar}
          onSiguienteClicked={onSiguienteClicked}
          fechasLibres={fechasLibres}
          setFecha={setFecha}
          fecha={fecha}
          setProfesional={setProfesional}
          profesional={profesional}
          setSelectedProfTurno={setSelectedProfTurno}
          selectedProfTurno={selectedProfTurno}
          setSelectedTurno={setSelectedTurno}
          selectedTurno={selectedTurno}
          posiblesProfesionalTurnos={posiblesProfTurnos}
          turnosHorarios={turnosHorarios}
          tabsSeleccionadas={tabsTurnoSeleccionadas}
          setTabsSeleccionadas={setTabsTurnoSeleccionadas}
          loadingFechas={loadFechas}
          loadingTurnos={loadTurnos}
          profesionalSobreturno={profSobreturno}
          setProfesionalSobreturno={setProfSobreturno}
          horarioSobreturno={horaSobreturno}
          setHorarioSobreturno={setHoraSobreturno}
          listadoMedicos={listadoMedicos}
          loadingMedicos={loadingMedicos}
        />
      ),
      onAtras: () => {
        setFecha(undefined);
        setProfesional(undefined);
        setSelectedProfTurno(undefined);
        setSelectedTurno("");
        setProfSobreturno(null);
        setHoraSobreturno(null);
      },
    },
    {
      titulo: "Datos del Paciente",
      content: (
        <DatosPaciente
          onHabilitarSiguiente={setPuedeContinuar}
          siguienteHabilitado={puedeContinuar}
          motivos={motivos}
          setMotivo={setMotivo}
          motivo={motivo}
          cuil={cuil}
          setCuil={setCuil}
          fechaNac={fechaNac}
          setFechaNac={setFechaNac}
          genero={genero}
          setGenero={setGenero}
          nombre={nombre}
          setNombre={setNombre}
          apellido={apellido}
          setApellido={setApellido}
          email={email}
          setEmail={setEmail}
          celular={celular}
          setCelular={setCelular}
          numCredencial={numCredencial}
          setNumCredencial={setNumCredencial}
          financiador={financiador!}
          loadingBuscarCUIL={loadingBuscarCUIL}
          buscarCUIL={buscarPorCUIL}
          datosLocked={datosLocked}
          credencialLocked={credencialLocked}
        />
      ),
      onAtras: () => {
        setMotivo("");
        setCuil("");
        setFechaNac(null);
        setGenero(undefined);
        setNombre("");
        setApellido("");
        setEmail("");
        setCelular("");
        setNumCredencial("");
        setDatosLocked(false);
        setCredencialLocked(false);
      },
    },
  ];
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Card style={{flexGrow: 1, marginBottom: 20}}>
          <CardHeader
            avatar={
              <Avatar>
                <AddOutlined />
              </Avatar>
            }
            title={<Typography variant="h6">Nuevo Turno</Typography>}
          />
          <Divider />
          <CardContent>
            <Stepper alternativeLabel activeStep={paso}>
              {pasos.map((objPaso, iObjPaso) => {
                return (
                  <Step key={iObjPaso}>
                    <StepLabel>{objPaso.titulo}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Paper style={{padding: "30px 15px 30px 15px"}} variant="outlined">
              {paso < pasos.length ? (
                pasos[paso].content
              ) : (
                <TurnoConfirmado
                  loading={loadingConfirmar}
                  financiador={financiador!}
                  especialidad={especialidad!}
                  profesional={
                    selectedProfTurno
                      ? selectedProfTurno.nombre
                      : listadoMedicos
                          .find((x) => x.email === profSobreturno)!
                          .profesional.split(":")[0]
                  }
                  matricula={
                    selectedProfTurno
                      ? selectedProfTurno.matricula
                      : Number.parseInt(
                          listadoMedicos
                            .find((x) => x.email === profSobreturno)!
                            .profesional.split(":")[1]
                        )
                  }
                  selectedTurno={
                    selectedProfTurno
                      ? selectedTurno
                      : convertDateToTimestring(horaSobreturno!)
                  }
                  fecha={fecha!}
                  cuil={cuil}
                  fechaNac={fechaNac!}
                  genero={genero!}
                  motivo={motivo}
                  nombre={nombre}
                  apellido={apellido}
                  email={email}
                  numAsociado={numCredencial}
                  celular={celular}
                />
              )}
            </Paper>
            <Toolbar>
              {paso < pasos.length && pasos[paso].onAtras ? (
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    pasos[paso].onAtras!();
                    setPaso(paso - 1);
                    setPuedeContinuar(true);
                  }}
                >
                  Atras
                </Button>
              ) : null}
              <span style={{flexGrow: 1}} />
              {paso < pasos.length ? (
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  disabled={!puedeContinuar}
                  onClick={onSiguienteClicked}
                >
                  {paso === pasos.length - 1 ? "Confirmar" : "Siguiente"}
                </Button>
              ) : null}
            </Toolbar>
          </CardContent>
        </Card>
        <div className={classes.buttonsRoot}>
          <Volver
            onClickHandler={() => {
              history.goBack();
            }}
          />
        </div>
        <Dialog open={error400 !== undefined && error400.length > 0}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <DialogContentText>{error400}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                localStorage.removeItem("Error400");
                history.push("/dashboard");
              }}
              variant="contained"
              disableElevation
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={mensajeDialog !== ""}>
          <DialogContent>
            <DialogContentText>{mensajeDialog}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setMensajeDialog("");
              }}
              variant="contained"
              disableElevation
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default withStyles(styles)(NuevoTurno);
