import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router-dom";

import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import VerAgendaCitas from "../../../pages/ProfesionalMedico/AtenderConsultorio/VerAgendaCitas/VerAgendaCitas";
import DatosPacienteProgramado from "../../../pages/ProfesionalMedico/AtenderConsultorio/DatosPacienteProgramado/DatosPacienteProgramado";
import ConsultorioMedicoProgramadas from "../../../pages/ProfesionalMedico/AtenderConsultorio/ConsultorioMedicoProgramadas/ConsultorioMedicoProgramadas";

const AtenderConsultorio = () => {
  const {path, url} = useRouteMatch();

  return (
    <Switch>
      <Redirect from={path} to={`${url}/ver-agenda-citas`} exact />
      <PrivateRoute
        component={VerAgendaCitas}
        path={`${path}/ver-agenda-citas`}
      />
      <PrivateRoute
        component={DatosPacienteProgramado}
        path={`${path}/datos-paciente`}
      />
      <PrivateRoute
        component={ConsultorioMedicoProgramadas}
        path={`${path}/consultorio-medico`}
      />
      {/*<PrivateRoute component={SeleccionarEspecialidad} path={`${path}/seleccionar-especialidad`} />
            <PrivateRoute component={SeleccionarTurno} path={`${path}/seleccionar-turno`} />
            <PrivateRoute component={ConfirmarTurno} path={`${path}/confirmar-turno`} /> */}
    </Switch>
  );
};

export default AtenderConsultorio;
