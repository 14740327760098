import React from "react";
import {Avatar, Box, IconButton, makeStyles, useTheme} from "@material-ui/core";
import {ReactComponent as UserIcon} from "../../../../assets/icons/icon_user.svg";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/rootReducer";
import {PersonOutline} from "@material-ui/icons";
import Icon_userjsx from "../../../../assets/icons/icon_user";

const useStyles = makeStyles((theme) => ({
  userData: {
    maxWidth: 300,
    color: theme.paletaColores.negro.segundo,
  },
  plan: {
    fontSize: 10,
    textAlign: "end",
  },
  welcome: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

interface OwnProps {
  plan?: string;
  NOMBRE: string;
  APELLIDO: string;
  onClickHandler: () => void;
  showData?: boolean;
}
type PublicProps = OwnProps;
type Props = PublicProps;

const User: React.FC<Props> = (props) => {
  const theme = useTheme();

  const {rol, photoURL} = useSelector((state: RootState) => state.user);

  const {plan, NOMBRE, APELLIDO, onClickHandler, showData = true} = props;
  const classes = useStyles();
  return (
    <Box display="flex">
      {showData ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className={classes.userData}
        >
          {plan && <span className={classes.plan}>{plan}</span>}
          <span
            className={classes.welcome}
          >{`Hola ${NOMBRE ? NOMBRE : ''} ${APELLIDO ? APELLIDO : ''}`}</span>
        </Box>
      ) : null}
      <IconButton aria-label="menú" onClick={onClickHandler}>
        {rol === "OPERADOR" || rol === "PACIENTE" ? (
          <Icon_userjsx nombre={NOMBRE} apellido={APELLIDO} props={props} />
        ) : (
          <Avatar>
            <img src={photoURL} alt="imagen medico" height="100%" />
          </Avatar>
        )}
      </IconButton>
    </Box>
  );
};

export default User as React.ComponentType<PublicProps>;
