import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import clsx from "clsx";
import SwipeableViews from "react-swipeable-views";
import {
  Divider,
  WithStyles,
  Button,
  Grid,
  Typography,
  TextField,
  Fab,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Drawer,
  IconButton,
  Card,
  DialogTitle,
  CardContent,
  Checkbox,
  FormControlLabel,
  Chip,
  Avatar,
  Box,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  CardHeader,
  FormGroup
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useTheme} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CreateIcon from "@material-ui/icons/Create";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";

import {styles, handleResize} from "./ConsultorioMedicoProgramadasStyles";
import {IArchivoSubido} from "../../../../Interfaces/IArchivoSubido";
import {
  cargarHistoriaClinica,
  buscarEnfermedadesCie10,
  obtenerMedicamentos,
} from "../../../../apis/historiaClinicaAPI";
import {IPostHistoriaClinica} from "../../../../Interfaces/IPostHistoriaClinica";
import {RootState} from "../../../../store/rootReducer";
import CardArchivoSubido from "../../../../components/CardArchivoSubido/CardArchivoSubido";
import {IEnfermedadCie10} from "../../../../Interfaces/IEnfermedadCie10";
import {
  CheckCircleOutline,
  Close,
  Delete,
  Edit,
  HistoryRounded,
} from "@material-ui/icons";
import {IPractica} from "../../../../Interfaces/IPractica";
import {IDatosPDF} from "../../../../Interfaces/IDatosPDF";
import {IMedicamentos} from "../../../../Interfaces/IMedicamentos";
import {IRecetaHistoriaClinica} from "../../../../Interfaces/IRecetaHistoriaClinica";
import {IMedicamentoRecetado} from "../../../../Interfaces/IMedicamentoRecetado";
import Jitsi from "../../../../components/jitsi/Jitsi";
import Turn from "../../../../components/turn/Turn";
import {getFirestore} from "../../../../db";
import {drawerWidth} from "./ConsultorioMedicoProgramadasStyles";
import {withStyles} from "@material-ui/styles";
import LoadingBubbles from "../../../../components/LoadingBubbles/LoadingBubbles";
import {IResultadoBusqueda} from "../../../../Interfaces/IResultadoBusqueda";
import {buscarPractica} from "../../../../apis/practicasAPI";
import {IDatosCitaProgramada} from "../../../../Interfaces/IDatosCitaProgramada";
import {
  cancelarCita,
  verDatosCita,
  setFinalizarBtn,
} from "../../../../apis/citaProgramadaAPI";
import {
  borrarArchivo,
  cargarEncuestaMedico,
  devolverSalaEspera,
  registrarEgresoPaciente,
} from "../../../../apis/citaInstantaneaAPI";
import {Pagination, Rating} from "@material-ui/lab";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import {
  convertDateToLocaleLongDateOnly,
  _calculateAgeDetailed,
  _getDateAsIs,
} from "../../../../utils/dateTimeHelper";
import CardHistoriaClinica from "../../../../components/CardHistoriaClinica/CardHistoriaClinica";
import {useBeforeunload} from "react-beforeunload";
import FileUploadModule from "../../../../components/FileUploadModule/FileUploadModule";
import {IInterconsultaHistoriaClinica} from "../../../../Interfaces/IInterconsultaHistoriaClinica";
import {IEspecialidad} from "../../../../Interfaces/IEspecialidad";
import {
  getEspecialidades,
  getEspecialidadesInter,
} from "../../../../apis/especialidadAPI";

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ConsultorioMedicoProgramadas: React.FC<WithStyles<typeof styles>> = (
  props
) => {
  const {classes} = props;
  const history = useHistory();

  const {idSalaVideollamada, cuilPacienteSeleccionado} = useSelector(
    (state: RootState) => state.atenderConsultorio
  );

  const [listaArchivosPaciente, setListaArchivosPaciente] = useState<
    Array<IArchivoSubido>
  >([]);
  const [listaArchivosProfesional, setListaArchivosProfesional] = useState<
    Array<IArchivoSubido>
  >([]);
  const [cita, setCita] = useState<IDatosCitaProgramada | null>(null);

  const [enfermedadesCie10, setEnfermedadesCie10] = useState<
    Array<IEnfermedadCie10>
  >([]);
  const [rdosAmbuLab, setRdosAmbuLab] = useState<Array<IPractica>>([]);
  const [rdosAmbuImg, setRdosAmbuImg] = useState<Array<IPractica>>([]);
  const [rdosAmbuEstPract, setRdosAmbuEstPract] = useState<Array<IPractica>>(
    []
  );
  const [rdosAmbuConsulta, setRdosAmbuConsulta] = useState<Array<IPractica>>(
    []
  );
  const [rdosOdontoConsulta, setRdosOdontoConsulta] = useState<
    Array<IPractica>
  >([]);
  const [rdosOdontoPerio, setRdosOdontoPerio] = useState<Array<IPractica>>([]);
  const [rdosOdontoRadio, setRdosOdontoRadio] = useState<Array<IPractica>>([]);
  const [medicamentos, setMedicamentos] = useState<Array<IMedicamentos>>([]);
  // const [practicasOtrasCie10, setPracticasOtrasCie10] = useState<Array<IPractica>>([]);
  const [isAceptarRecetaDisabled, setAceptarRecetaDisabled] = useState(true);
  const [isAceptarInterconsultaDisabled, setAceptarInterconsultaDisabled] =
    useState(true);
  const [mostrarRegistroProfesional, setMostrarRegistroProfesional] =
    useState(false);
  const [tratamientoProlongado, setTratamientoProlongado] = useState(false);
  const [cancelarCitaOpen, setCancelarCitaOpen] = useState(false);
  const [devolverEsperaOpen, setDevolverEsperaOpen] = useState(false);
  const [diagnosticoReceta, setDiagnosticoReceta] = useState<
    IEnfermedadCie10[]
  >([]);

  const [diagnosticoInterconsulta, setDiagnosticoInterconsulta] = useState<
    IEnfermedadCie10[]
  >([]);
  const [especialidadInterconsulta, setEspecialidadInterconsulta] =
    useState<string>("");
  const [resumenInterconsulta, setResumenInterconsulta] = useState<string>("");
  const [parametrosReceta, setParametrosReceta] = useState({
    generico: false,
    duplicado: false
  })

  const onDeleteFile = (linkArchivo: string) => {
    borrarArchivo(
      idSalaVideollamada,
      cuilPacienteSeleccionado!.toString(),
      linkArchivo,
      "profesional"
    )
      .then((datosrecibidos) => {
        console.log("archivo borrado");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const db = getFirestore();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEspecialidadesInter()
      .then((datos) => {
        setListaEspecialidadesApi(datos);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useBeforeunload((event) => {
    event.preventDefault();
  });

  useEffect(() => {
    //pacientes/20310481905/Consultas/14eebd34-69da-43c5-ae74-fd009a67b7ab/Resources
    const unsubscribeEvent1 = db
      .collection("pacientes")
      .doc(cuilPacienteSeleccionado!.toString())
      .collection("Consultas")
      .doc(idSalaVideollamada)
      .collection("Resources")
      .onSnapshot((archivosEnLaNube) => {
        let archivosTemp: Array<IArchivoSubido> = [];
        archivosEnLaNube.forEach((archivo) => {
          let nombreArchivoParts: string[] = archivo.data().file.split("/");
          let nombreArchivo = nombreArchivoParts[nombreArchivoParts.length - 1];
          archivosTemp.push({
            file: nombreArchivo,
            descripcion: archivo.data().descripcion,
            linkArchivo: archivo.data().linkArchivo
              ? archivo.data().linkArchivo
              : "",
            fecha: archivo.data().fecha,
          });
        });
        setListaArchivosPaciente(archivosTemp);
      });
    const unsubscribeEvent2 = db
      .collection("pacientes")
      .doc(cuilPacienteSeleccionado!.toString())
      .collection("Consultas")
      .doc(idSalaVideollamada)
      .collection("SubidoProfesional")
      .onSnapshot((archivosEnLaNube) => {
        let archivosTemp: Array<IArchivoSubido> = [];
        archivosEnLaNube.forEach((archivo) => {
          let nombreArchivoParts: string[] = archivo.data().file.split("/");
          let nombreArchivo = nombreArchivoParts[nombreArchivoParts.length - 1];
          archivosTemp.push({
            file: nombreArchivo,
            descripcion: archivo.data().descripcion,
            linkArchivo: archivo.data().linkArchivo
              ? archivo.data().linkArchivo
              : "",
            fecha: archivo.data().fecha,
          });
        });
        setListaArchivosProfesional(archivosTemp);
      });

    verDatosCita({
      usuario: cuilPacienteSeleccionado.toString(),
      sesion: idSalaVideollamada,
    })
      .then((data) => {
        data.historialClinico.sort((a, b) => {
          return b.fecha._seconds - a.fecha._seconds;
        });
        setCita(data);
      })
      .catch((e) => {
        console.log(e);
      });

    return () => {
      unsubscribeEvent1();
      unsubscribeEvent2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {NOMBRE, APELLIDO, email} = useSelector(
    (state: RootState) => state.user
  );

  let datosObligatorios = true;
  let hayInterconsultas = true;
  const emailSeparado = email.split("@");
  const dominioSeparado = emailSeparado[1].split(".");
  if (dominioSeparado[0].toLowerCase() === "sanatoriofinochietto") {
    datosObligatorios = false;
    hayInterconsultas = false;
  }
  if (
    cita &&
    cita!.FinanciadorData &&
    cita!.FinanciadorData!.Nombre.toLowerCase() === "medife"
  ) {
    datosObligatorios = true;
    hayInterconsultas = true;
  }

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [buscandoCie10, setBuscandoCie10] = React.useState(false);
  const [buscandoAmbuLab, setBuscandoAmbuLab] = React.useState(false);
  const [buscandoAmbuImg, setBuscandoAmbuImg] = React.useState(false);
  const [buscandoAmbuEstPract, setBuscandoAmbuEstPract] = React.useState(false);
  const [buscandoAmbuConsulta, setBuscandoAmbuConsulta] = React.useState(false);
  const [buscandoOdontoConsulta, setBuscandoOdontoConsulta] =
    React.useState(false);
  const [buscandoOdontoPerio, setBuscandoOdontoPerio] = React.useState(false);
  const [buscandoOdontoRadio, setBuscandoOdontoRadio] = React.useState(false);
  const [buscandoMedicamento, setBuscandoMedicamento] = React.useState(false);

  const [listaEspecialidadesApi, setListaEspecialidadesApi] = useState<
    Array<string>
  >([]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleChangeDiagnosticoPresuntivo = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoCie10(true);
      let busqueda = target.value;
      const cie10: IResultadoBusqueda<IEnfermedadCie10> =
        await buscarEnfermedadesCie10(target.value);
      if (busqueda === target.value) {
        let temp = cie10.arrayResultados.filter((enfermedad) => {
          for (let index = 0; index < diagnosticoPresuntivo.length; index++) {
            const element = diagnosticoPresuntivo[index];
            if (element.ID === enfermedad.ID) {
              return false;
            }
          }
          return true;
        });
        setEnfermedadesCie10(temp as any);
        setBuscandoCie10(false);
      }
    } else {
      setEnfermedadesCie10([]);
      setBuscandoCie10(false);
    }
  };
  const handleBuscarAmbuLab = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoAmbuLab(true);
      let busqueda = target.value;
      const practicas = await buscarPractica(
        target.value,
        "LaboratorioAmbulatorio"
      );
      if (busqueda === target.value) {
        let temp = practicas.filter((practica) => {
          for (let index = 0; index < selecAmbuLab.length; index++) {
            const element = selecAmbuLab[index];
            if (element.codigo === practica.codigo) {
              return false;
            }
          }
          return true;
        });
        setRdosAmbuLab(temp as any);
        setBuscandoAmbuLab(false);
      }
    } else {
      setRdosAmbuLab([] as any);
      setBuscandoAmbuLab(false);
    }
  };
  const handleBuscarAmbuImg = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoAmbuImg(true);
      let busqueda = target.value;
      const practicas = await buscarPractica(
        target.value,
        "ImagenesAmbulatorio"
      );
      if (busqueda === target.value) {
        let temp = practicas.filter((practica) => {
          for (let index = 0; index < selecAmbuImg.length; index++) {
            const element = selecAmbuImg[index];
            if (element.codigo === practica.codigo) {
              return false;
            }
          }
          return true;
        });
        setRdosAmbuImg(temp as any);
        setBuscandoAmbuImg(false);
      }
    } else {
      setRdosAmbuImg([] as any);
      setBuscandoAmbuImg(false);
    }
  };
  const handleBuscarAmbuEstPract = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoAmbuEstPract(true);
      let busqueda = target.value;
      const practicas = await buscarPractica(
        target.value,
        "EstudiosPracticasAmbulatorio"
      );
      if (busqueda === target.value) {
        let temp = practicas.filter((practica) => {
          for (let index = 0; index < selecAmbuEstPract.length; index++) {
            const element = selecAmbuEstPract[index];
            if (element.codigo === practica.codigo) {
              return false;
            }
          }
          return true;
        });
        setRdosAmbuEstPract(temp as any);
        setBuscandoAmbuEstPract(false);
      }
    } else {
      setRdosAmbuEstPract([] as any);
      setBuscandoAmbuEstPract(false);
    }
  };
  const handleBuscarAmbuConsulta = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoAmbuConsulta(true);
      let busqueda = target.value;
      const practicas = await buscarPractica(
        target.value,
        "ConsultasAmbulatorio"
      );
      if (busqueda === target.value) {
        let temp = practicas.filter((practica) => {
          for (let index = 0; index < selecAmbuConsulta.length; index++) {
            const element = selecAmbuConsulta[index];
            if (element.codigo === practica.codigo) {
              return false;
            }
          }
          return true;
        });
        setRdosAmbuConsulta(temp as any);
        setBuscandoAmbuConsulta(false);
      }
    } else {
      setRdosAmbuConsulta([] as any);
      setBuscandoAmbuConsulta(false);
    }
  };
  const handleBuscarOdontoConsulta = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoOdontoConsulta(true);
      let busqueda = target.value;
      const practicas = await buscarPractica(
        target.value,
        "ConsultasOdontologia"
      );
      if (busqueda === target.value) {
        let temp = practicas.filter((practica) => {
          for (let index = 0; index < selecOdontoConsulta.length; index++) {
            const element = selecOdontoConsulta[index];
            if (element.codigo === practica.codigo) {
              return false;
            }
          }
          return true;
        });
        setRdosOdontoConsulta(temp as any);
        setBuscandoOdontoConsulta(false);
      }
    } else {
      setRdosOdontoConsulta([] as any);
      setBuscandoOdontoConsulta(false);
    }
  };
  const handleBuscarOdontoRadio = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoOdontoRadio(true);
      let busqueda = target.value;
      const practicas = await buscarPractica(
        target.value,
        "RadiologiaOdontologia"
      );
      if (busqueda === target.value) {
        let temp = practicas.filter((practica) => {
          for (let index = 0; index < selecOdontoRadio.length; index++) {
            const element = selecOdontoRadio[index];
            if (element.codigo === practica.codigo) {
              return false;
            }
          }
          return true;
        });
        setRdosOdontoRadio(temp as any);
        setBuscandoOdontoRadio(false);
      }
    } else {
      setRdosOdontoRadio([] as any);
      setBuscandoOdontoRadio(false);
    }
  };
  const handleBuscarOdontoPerio = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoOdontoPerio(true);
      let busqueda = target.value;
      const practicas = await buscarPractica(
        target.value,
        "PeriodonciaOdontologia"
      );
      if (busqueda === target.value) {
        let temp = practicas.filter((practica) => {
          for (let index = 0; index < selecOdontoPerio.length; index++) {
            const element = selecOdontoPerio[index];
            if (element.codigo === practica.codigo) {
              return false;
            }
          }
          return true;
        });
        setRdosOdontoPerio(temp as any);
        setBuscandoOdontoPerio(false);
      }
    } else {
      setRdosOdontoPerio([] as any);
      setBuscandoOdontoPerio(false);
    }
  };
  const handleChangeMedicamentos = async (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    if (target.value.length > 0) {
      setBuscandoMedicamento(true);
      let busqueda = target.value;
      const medicamentos: IResultadoBusqueda<IMedicamentos> =
        await obtenerMedicamentos(target.value);
      if (busqueda === target.value) {
        let temp = medicamentos.arrayResultados.filter((medicamento) => {
          for (
            let index = 0;
            index < medicamentosSeleccionados.length;
            index++
          ) {
            const element = medicamentosSeleccionados[index];
            if (element?.numeroRegistro === medicamento.numeroRegistro) {
              return false;
            }
          }
          return true;
        });
        setMedicamentos(temp as any);
        setBuscandoMedicamento(false);
      }
    } else {
      setMedicamentos([] as any);
      setBuscandoMedicamento(false);
    }
  };

  const [motivoExtendidoConsulta, setMotivoExtendidoConsulta] =
    useState<string>("");
  const [descripcionEnfermedadActual, setDescripcionEnfermedadActual] =
    useState<string>("");
  const [diagnosticoPresuntivo, setDiagnosticoPresuntivo] = useState<
    IEnfermedadCie10[]
  >([]);

  const [selecAmbuLab, setSelecAmbuLab] = useState<IPractica[]>([]);
  const [selecAmbuImg, setSelecAmbuImg] = useState<IPractica[]>([]);
  const [selecAmbuEstPract, setSelecAmbuEstPract] = useState<IPractica[]>([]);
  const [selecAmbuConsulta, setSelecAmbuConsulta] = useState<IPractica[]>([]);
  const [selecOdontoConsulta, setSelecOdontoConsulta] = useState<IPractica[]>(
    []
  );
  const [selecOdontoPerio, setSelecOdontoPerio] = useState<IPractica[]>([]);
  const [selecOdontoRadio, setSelecOdontoRadio] = useState<IPractica[]>([]);

  const [recetasArmadas, setRecetasArmadas] = useState<
    IRecetaHistoriaClinica[]
  >([]);
  const [interconsultasArmadas, setInterconsultasArmadas] = useState<
    IInterconsultaHistoriaClinica[]
  >([]);
  const [medicamentosSeleccionados, setMedicamentosSeleccionados] = useState<
    Array<IMedicamentoRecetado | null>
  >([null]);
  const [indicadores, setIndicadores] = useState<string>("");
  const [signosAlerta, setSignosAlerta] = useState<string>("");
  const [notasPrivadas, setNotasPrivadas] = useState<string>("");
  const [estadoRegistro, setEstadoRegistro] = useState<
    "enviando" | "enviado" | "llenando_datos"
  >("llenando_datos");
  const [dialogEnviarOpen, setDialogEnviarOpen] = useState<boolean>(false);
  const [dialogEditarRecetaOpen, setDialogEditarRecetaOpen] =
    useState<boolean>(false);
  const [disabledAgregar, setDisabledAgregar] = useState<boolean>(false);

  const [dialogInterconsultaOpen, setDialogInterconsultaOpen] =
    useState<boolean>(false);
  //const [opcionSalida, setOpcionSalida] = useState<"enviar-historial" | "cancelar-cita" | "devolver-espera">("enviar-historial");

  let onFinalizarBtn = async () => {
    console.log("Se clickeo en finalizar llamada Jitsi");
    if (cita?.especialidad) {
      setFinalizarBtn(cita, cuilPacienteSeleccionado.toString());
    }
  };
  useEffect(() => {
    onFinalizarBtn = async () => {
      console.log("Se clickeo en finalizar llamada Jitsi - useEffect");
      if (cita?.especialidad) {
        setFinalizarBtn(cita, cuilPacienteSeleccionado.toString());
      }
    };
  }, [cita]);

  useEffect(() => {
    const isAceptarDisabled = medicamentosSeleccionados.some((medicamento) => {
      if (medicamento) {
        return (
          !medicamento.cantidadEnvases ||
          medicamento.indicaciones.trim() === "" ||
          diagnosticoReceta.length === 0
        );
      }
      return true;
    });
    setAceptarRecetaDisabled(isAceptarDisabled);
  }, [medicamentosSeleccionados, diagnosticoReceta]);

  useEffect(() => {
    let isAceptarDisabled = false;
    if (especialidadInterconsulta === "") {
      isAceptarDisabled = true;
    }
    if (diagnosticoInterconsulta.length === 0) {
      isAceptarDisabled = true;
    }
    setAceptarInterconsultaDisabled(isAceptarDisabled);
  }, [especialidadInterconsulta, diagnosticoInterconsulta]);

  const StartEnviarHistoriaClinica = () => {
    setDialogEnviarOpen(true);
  };
  /*
    const StartCancelarCita = () => {
        setOpcionSalida("cancelar-cita");
        setDialogEnviarOpen(true);
    }

    const StartDevolverEspera = () => {
        setOpcionSalida("devolver-espera");
        setDialogEnviarOpen(true);
    }
    */
  const SubirHistoriaClinica = async () => {
    setEstadoRegistro("enviando");

    let diagnosticoString = "";
    diagnosticoPresuntivo?.forEach((enfermedad, index) => {
      diagnosticoString =
        diagnosticoString + `(${enfermedad.ID}) ${enfermedad.Name}, `;
    });

    let documentacion: Array<IDatosPDF> = [];
    let dniPacienteSeleccionado: string = cuilPacienteSeleccionado!
      .toString()
      .substring(2, 10);
    selecAmbuLab?.forEach((practicaLab, index) => {
      let dato: IDatosPDF = {
        practica: practicaLab.descripcion,
        fecha: new Date().toString(),
        nombreCompleto: "mockNombre",
        dni: dniPacienteSeleccionado,
        nroAfiliado: "mockAfiliado",
        diagnostico: diagnosticoString,
        nombreMedico: `${NOMBRE} ${APELLIDO}`,
        matriculaMedico: "mockMatriculaMedico",
      };
      documentacion.push(dato);
    });
    selecAmbuImg?.forEach((practicaImg, index) => {
      let dato: IDatosPDF = {
        practica: practicaImg.descripcion,
        fecha: new Date().toString(),
        nombreCompleto: "mockNombre",
        dni: dniPacienteSeleccionado,
        nroAfiliado: "mockAfiliado",
        diagnostico: diagnosticoString,
        nombreMedico: `${NOMBRE} ${APELLIDO}`,
        matriculaMedico: "mockMatriculaMedico",
      };
      documentacion.push(dato);
    });
    let historia: IPostHistoriaClinica = {
      usuario: cuilPacienteSeleccionado!.toString(),
      sesion: idSalaVideollamada,
      diagnosticoPresuntivo: diagnosticoPresuntivo!,
      enfermedadActual: descripcionEnfermedadActual,
      motivoConsulta: motivoExtendidoConsulta,
      indicaciones: indicadores,
      signosAlerta: signosAlerta,
      notasPrivadas: notasPrivadas,
      ambuImg: selecAmbuImg.length > 0 ? selecAmbuImg : undefined,
      ambuLab: selecAmbuLab.length > 0 ? selecAmbuLab : undefined,
      ambuEstPract:
        selecAmbuEstPract.length > 0 ? selecAmbuEstPract : undefined,
      ambuConsulta:
        selecAmbuConsulta.length > 0 ? selecAmbuConsulta : undefined,
      odontoConsulta:
        selecOdontoConsulta.length > 0 ? selecOdontoConsulta : undefined,
      odontoPerio: selecOdontoPerio.length > 0 ? selecOdontoPerio : undefined,
      odontoRadio: selecOdontoRadio.length > 0 ? selecOdontoRadio : undefined,
      recetas: recetasArmadas.length > 0 ? recetasArmadas : undefined,
      interconsultas:
        interconsultasArmadas.length > 0
          ? {
              idSesion: idSalaVideollamada,
              ListInterconsultas: interconsultasArmadas,
            }
          : undefined,
      emailProfesional: email,
      duplicada: parametrosReceta.duplicado,
      generica: parametrosReceta.generico
    };
    await cargarHistoriaClinica(historia);
    setEstadoRegistro("enviado");
    setFinalizada(true);
  };

  const handleCloseDialogEnviar = () => {
    setDialogEnviarOpen(false);
  };
  const openDialogEditarReceta = () => {
    setDialogEditarRecetaOpen(true);
  };
  const openDialogInterconsulta = () => {
    setDialogInterconsultaOpen(true);
  };
  const closeDialogEditarReceta = () => {
    setDialogEditarRecetaOpen(false);
  };
  const closeDialogInterconsulta = () => {
    setDialogInterconsultaOpen(false);
  };
  const acceptDialogEditarReceta = () => {
    let recetasTemp = [...recetasArmadas];
    let medicamentosFiltered: Array<IMedicamentoRecetado> = [];
    medicamentosSeleccionados.forEach((medicamento, index) => {
      if (medicamento !== null) {
        medicamentosFiltered.push(medicamento!);
      }
    });
    let recetaNueva: IRecetaHistoriaClinica = {
      medicamentos: medicamentosFiltered,
      tratamientoProlongado: tratamientoProlongado ? "true" : "false",
      diagnosticoReceta: diagnosticoReceta,
    };
    if (agregandoRecetaNueva) {
      recetasTemp.push(recetaNueva);
    } else {
      recetasTemp[indexRecetaEditar] = recetaNueva;
    }
    setRecetasArmadas(recetasTemp);
    setMedicamentosSeleccionados([null]);
    setDialogEditarRecetaOpen(false);
  };
  const acceptDialogEditarInterconsulta = () => {
    let interconsultasTemp = [...interconsultasArmadas];
    let interconsultaNueva: IInterconsultaHistoriaClinica = {
      especialidad: especialidadInterconsulta,
      evaluacion: diagnosticoInterconsulta,
      descripcion: resumenInterconsulta,
    };
    if (agregandoInterconsultaNueva) {
      interconsultasTemp.push(interconsultaNueva);
    } else {
      interconsultasTemp[indexInterconsultaEditar] = interconsultaNueva;
    }
    setInterconsultasArmadas(interconsultasTemp);
    setDialogInterconsultaOpen(false);
  };

  /*Valido que sean solo 3*/
  useEffect(() => {
    let interconsultasTemp = [...interconsultasArmadas];
    if (interconsultasTemp.length >= 3) {
      setDisabledAgregar(true);
      return;
    } else {
      setDisabledAgregar(false);
    }
  }, [interconsultasArmadas]);

  const [loadingSalir, setLoadingSalir] = React.useState(false);

  const handleEnviarDialogSi = async () => {
    setDialogEnviarOpen(false);
    SubirHistoriaClinica();
  };

  const styles = {
    tabs: {
      background: "#fff",
    },
    tab: {
      fontSize: 11,
      minWidth: "20px",
      maxWidth: "35%",
    },
    slide: {
      padding: 15,
      minHeight: 100,
      minWidth: "100px",
    },
  };

  const [agregandoRecetaNueva, setAgregandoRecetaNueva] = useState(true);
  const [indexRecetaEditar, setIndexRecetaEditar] = useState(0);

  const [agregandoInterconsultaNueva, setAgregandoInterconsultaNueva] =
    useState(true);
  const [indexInterconsultaEditar, setIndexInterconsultaEditar] = useState(0);

  const onMedicamentoPropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    indexMedicamento: number,
    prop: string
  ) => {
    setMedicamentosSeleccionados((prevMedicamentos) => {
      const newMedicamentos = [...prevMedicamentos];
      const medicamento = newMedicamentos[indexMedicamento];
      if (medicamento) {
        if (prop === "indicaciones")
          medicamento.indicaciones = event.target.value;
        if (prop === "cantidadEnvases") {
          let valStr = event.target.value;
          if (valStr.indexOf(",") !== -1) {
            valStr = valStr.split(",")[0];
          }
          if (valStr.indexOf(".") !== -1) {
            valStr = valStr.split(".")[0];
          }
          // const roundedValue = parseInt(valStr);
          if (valStr === "") {
            medicamento.cantidadEnvases = undefined;
          } else {
            medicamento.cantidadEnvases = Math.max(+valStr, 1);
          }
        }
        if (prop === "permiteSustitucion")
          medicamento.permiteSustitucion = event.target.checked ? "S" : "N";
        return newMedicamentos;
      }
      return prevMedicamentos;
    });
  };

  const [dialogAmbulatoriaOpen, setDialogAmbulatoriaOpen] = useState(false);
  const handleCloseDialogAmbulatoria = () => {
    setDialogAmbulatoriaOpen(false);
  };
  const dialogAmbulatoria = (
    <Dialog
      open={dialogAmbulatoriaOpen}
      onClose={handleCloseDialogAmbulatoria}
      maxWidth={"md"}
      fullWidth
    >
      <>
        <DialogContent>
          <Autocomplete
            style={{marginBottom: 20}}
            multiple
            options={rdosAmbuLab}
            getOptionLabel={(option) =>
              `(${option.codigo}) ${option.descripcion}`
            }
            fullWidth
            value={selecAmbuLab}
            onChange={(event: any, newValue: IPractica[]) => {
              setSelecAmbuLab(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Laboratorio"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoAmbuLab ? <LoadingBubbles size={30} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleBuscarAmbuLab(e.target);
                }}
              />
            )}
          />
          <Autocomplete
            style={{marginBottom: 20}}
            multiple
            options={rdosAmbuImg}
            getOptionLabel={(option) =>
              `(${option.codigo}) ${option.descripcion}`
            }
            fullWidth
            value={selecAmbuImg}
            onChange={(event: any, newValue: IPractica[]) => {
              setSelecAmbuImg(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Imagenes"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoAmbuImg ? <LoadingBubbles size={30} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleBuscarAmbuImg(e.target);
                }}
              />
            )}
          />
          <Autocomplete
            style={{marginBottom: 20}}
            multiple
            options={rdosAmbuEstPract}
            getOptionLabel={(option) =>
              `(${option.codigo}) ${option.descripcion}`
            }
            fullWidth
            value={selecAmbuEstPract}
            onChange={(event: any, newValue: IPractica[]) => {
              setSelecAmbuEstPract(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estudios y Practicas"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoAmbuEstPract ? (
                        <LoadingBubbles size={30} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleBuscarAmbuEstPract(e.target);
                }}
              />
            )}
          />
          <Autocomplete
            multiple
            options={rdosAmbuConsulta}
            getOptionLabel={(option) =>
              `(${option.codigo}) ${option.descripcion}`
            }
            fullWidth
            value={selecAmbuConsulta}
            onChange={(event: any, newValue: IPractica[]) => {
              setSelecAmbuConsulta(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Consulta"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoAmbuConsulta ? (
                        <LoadingBubbles size={30} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleBuscarAmbuConsulta(e.target);
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={theme.buttons.okButton}
            disableElevation
            onClick={handleCloseDialogAmbulatoria}
          >
            Cerrar
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );

  const [dialogOdontoOpen, setDialogOdontoOpen] = useState(false);
  const handleCloseDialogOdonto = () => {
    setDialogOdontoOpen(false);
  };
  const dialogOdonto = (
    <Dialog
      open={dialogOdontoOpen}
      onClose={handleCloseDialogOdonto}
      maxWidth={"md"}
      fullWidth
    >
      <>
        <DialogContent>
          <Autocomplete
            style={{marginBottom: 20}}
            multiple
            options={rdosOdontoConsulta}
            getOptionLabel={(option) =>
              `(${option.codigo}) ${option.descripcion}`
            }
            fullWidth
            value={selecOdontoConsulta}
            onChange={(event: any, newValue: IPractica[]) => {
              setSelecOdontoConsulta(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Consulta"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoOdontoConsulta ? (
                        <LoadingBubbles size={30} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleBuscarOdontoConsulta(e.target);
                }}
              />
            )}
          />

          <Autocomplete
            style={{marginBottom: 20}}
            multiple
            options={rdosOdontoPerio}
            getOptionLabel={(option) =>
              `(${option.codigo}) ${option.descripcion}`
            }
            fullWidth
            value={selecOdontoPerio}
            onChange={(event: any, newValue: IPractica[]) => {
              setSelecOdontoPerio(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Periodoncia"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoOdontoPerio ? (
                        <LoadingBubbles size={30} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleBuscarOdontoPerio(e.target);
                }}
              />
            )}
          />

          <Autocomplete
            multiple
            options={rdosOdontoRadio}
            getOptionLabel={(option) =>
              `(${option.codigo}) ${option.descripcion}`
            }
            fullWidth
            value={selecOdontoRadio}
            onChange={(event: any, newValue: IPractica[]) => {
              setSelecOdontoRadio(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Radiología"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoOdontoRadio ? (
                        <LoadingBubbles size={30} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleBuscarOdontoRadio(e.target);
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={theme.buttons.okButton}
            disableElevation
            onClick={handleCloseDialogOdonto}
          >
            Cerrar
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );

  const offsetHours = -(new Date().getTimezoneOffset() / 60);
  let offset = offsetHours < 0 ? "-" : "+";
  offset += Math.abs(offsetHours) < 10 ? "0" : "";
  offset += Math.floor(Math.abs(offsetHours));
  const offsetHourDecimal =
    Math.abs(offsetHours) - Math.floor(Math.abs(offsetHours));
  const offsetMinutes = offsetHourDecimal * 60;
  offset += offsetMinutes < 10 ? "0" : "";
  offset += offsetMinutes;

  const [finalizada, setFinalizada] = useState(false);

  const [valueRatingPaciente, setValueRatingPaciente] = useState(0);
  const [valueRatingPlat, setValueRatingPlat] = useState(0);
  const [commentRatingPaciente, setCommentRatingPaciente] = useState("");
  const [commentRatingPlat, setCommentRatingPlat] = useState("");

  const [dialogHistorialOpen, setDialogHistorialOpen] = useState(false);
  const elementosPerPagHistorial = 5;
  const [paginaHistorial, setPaginaHistorial] = useState(0);

  if (loadingSalir) {
    return <LoadingBubbles />;
  }

  const enviarEncuesta = () => {
    setLoadingSalir(true);
    cargarEncuestaMedico(
      email,
      idSalaVideollamada,
      valueRatingPaciente,
      valueRatingPlat,
      commentRatingPaciente,
      commentRatingPlat
    )
      .then((recibido) => {
        setLoadingSalir(false);
        history.push("/dashboard");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (finalizada) {
    return (
      <Dialog open={finalizada}>
        <DialogTitle>Consulta finalizada</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            Gracias por utilizar CamDoctor, en breves instantes llegarán tus
            indicaciones a la casilla de correo del paciente.
          </Typography>
          <Typography paragraph>
            Por favor calificá tu experiencia en la plataforma
          </Typography>
          <Paper style={{padding: 10, margin: 10}}>
            <Typography>Calificacion del Paciente:</Typography>
            <Rating
              value={valueRatingPaciente}
              size="large"
              onChange={(event, newValue) => {
                if (newValue) {
                  setValueRatingPaciente(newValue!);
                }
              }}
            />
            <TextField
              fullWidth
              label="Comentarios"
              value={commentRatingPaciente}
              multiline
              spellCheck={true}
              onChange={(event) => {
                setCommentRatingPaciente(event.target.value as string);
              }}
            />
          </Paper>
          <Paper style={{padding: 10, margin: 10}}>
            <Typography>Calificacion de la Plataforma:</Typography>
            <Rating
              value={valueRatingPlat}
              size="large"
              onChange={(event, newValue) => {
                if (newValue) {
                  setValueRatingPlat(newValue!);
                }
              }}
            />
            <TextField
              fullWidth
              label="Comentarios"
              multiline
              spellCheck={true}
              value={commentRatingPlat}
              onChange={(event) => {
                setCommentRatingPlat(event.target.value as string);
              }}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={
              valueRatingPlat === 0 ||
              valueRatingPaciente === 0 ||
              valueRatingPlat === undefined ||
              valueRatingPaciente === undefined
            }
            onClick={enviarEncuesta}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const cantidadOdonto =
    selecOdontoConsulta.length +
    selecOdontoPerio.length +
    selecOdontoRadio.length;
  let textoOdonto = "Odontología";
  if (cantidadOdonto === 1) {
    textoOdonto = `Odontología (1 seleccionada)`;
  } else if (cantidadOdonto > 1) {
    textoOdonto = `Odontología (${cantidadOdonto} seleccionadas)`;
  }

  const cantidadAmbu =
    selecAmbuLab.length +
    selecAmbuImg.length +
    selecAmbuEstPract.length +
    selecAmbuConsulta.length;
  let textoAmbu = "Ambulatoria";
  if (cantidadAmbu === 1) {
    textoAmbu = `Ambulatoria (1 seleccionada)`;
  } else if (cantidadAmbu > 1) {
    textoAmbu = `Ambulatoria (${cantidadAmbu} seleccionadas)`;
  }

  return (
    <>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: mostrarRegistroProfesional,
        })}
      >
        <Fab
          style={{
            margin: 0,
            top: 120,
            right: 45,
            bottom: "auto",
            left: "auto",
            position: "fixed",
          }}
          variant="round"
          color="secondary"
          onClick={() => {
            setMostrarRegistroProfesional(!mostrarRegistroProfesional);
          }}
        >
          {mostrarRegistroProfesional ? <CancelIcon /> : <CreateIcon />}
        </Fab>
        <Grid container item direction="column" xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "75vh",
              marginTop: "-100px",
            }}
          >
            {
              <Jitsi
                showHangup={true}
                roomName={idSalaVideollamada}
                displayName={`Dr. ${NOMBRE} ${APELLIDO}`}
                showChat={true}
                showFullscreen={true}
                onFinalizarBtn={onFinalizarBtn}
              />
            }
          </div>

          <Paper>
            <Grid container direction="column">
              {cita && (
                <Grid container style={{padding: "10px"}} justify="center">
                  <FileUploadModule
                    usuario={cuilPacienteSeleccionado.toString()}
                    sesion={idSalaVideollamada}
                    origen="profesional"
                    onCompletedUploading={(newFile) => {}}
                  />
                </Grid>
              )}
              <Paper variant="outlined" style={{margin: 10}}>
                <Typography
                  variant="h6"
                  align="center"
                  style={{margin: "10px"}}
                >
                  Archivos del Paciente
                </Typography>
                <Grid container>
                  {listaArchivosPaciente.map((archivo, index) => {
                    let {file, descripcion, linkArchivo} = archivo;

                    return (
                      <Grid
                        key={index}
                        container
                        item
                        xs={12}
                        md={mostrarRegistroProfesional ? 12 : 6}
                        lg={mostrarRegistroProfesional ? 6 : 4}
                      >
                        <CardArchivoSubido
                          fileName={file}
                          description={descripcion}
                          downloadLink={linkArchivo}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
              <Paper variant="outlined" style={{margin: 10}}>
                <Typography
                  variant="h6"
                  align="center"
                  style={{margin: "10px"}}
                >
                  Archivos del Profesional
                </Typography>
                <Grid container>
                  {listaArchivosProfesional.map((archivo, index) => {
                    let {file, descripcion, linkArchivo} = archivo;

                    return (
                      <Grid
                        key={index}
                        container
                        item
                        xs={12}
                        md={mostrarRegistroProfesional ? 12 : 6}
                        lg={mostrarRegistroProfesional ? 6 : 4}
                      >
                        <CardArchivoSubido
                          canDelete={true}
                          onDelete={() => {
                            onDeleteFile(linkArchivo);
                          }}
                          fileName={file}
                          description={descripcion}
                          downloadLink={linkArchivo}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </Paper>
        </Grid>
      </main>
      <Drawer
        className={classes.drawer}
        classes={{paper: classes.docked}}
        variant="persistent"
        anchor="right"
        open={mostrarRegistroProfesional}
        style={{overflowX: "hidden"}}
      >
        <Grid container direction="column" className={classes.drawer}>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            style={{
              backgroundColor: theme.paletaColores.negro.segundo,
              color: "white",
            }}
          >
            <Typography variant="h5" style={{flexGrow: 1, marginLeft: 15}}>
              Registro Profesional
            </Typography>
            <IconButton
              onClick={() => {
                setMostrarRegistroProfesional(false);
              }}
              style={{color: "white"}}
            >
              <Close />{" "}
            </IconButton>
          </Grid>
        </Grid>

        {estadoRegistro === "llenando_datos" ? (
          <Grid container direction="column">
            <Grid item container xs={12} direction="row-reverse"></Grid>
            <Grid item container xs={12} className={classes.drawer}>
              <Grid item xs />
              <Grid
                item
                container
                xs={11}
                direction="column"
                style={{display: "block"}}
              >
                {cita && (
                  <Grid item xs={12} style={{display: "block"}}>
                    <Paper
                      variant="outlined"
                      style={{padding: 10, margin: 10, display: "block"}}
                    >
                      <Grid container>
                        <Grid item xs={12} style={{display: "block"}}>
                          <Typography
                            variant="body1"
                            style={{fontWeight: "bold", marginTop: 10}}
                          >
                            Paciente: {cita!.nombre}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{display: "block"}}>
                          <Typography variant="body1">
                            Email: {cita!.emailUsuario}
                          </Typography>
                          <Typography variant="body1">
                            DNI: {cita!.dni}
                          </Typography>
                          <Typography variant="body1">
                            Fecha de nacimiento:{" "}
                            {_getDateAsIs(cita!.fechaNacimiento)}
                          </Typography>
                          <Typography variant="body1">
                            Especialidad: {cita!.especialidad!}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            Plan: {cita && cita!.plan ? cita!.plan : "-"}
                          </Typography>
                          <Typography variant="body1">
                            Credencial: {cita?.credencial}
                          </Typography>
                          <Typography variant="body1">
                            Edad:{" "}
                            {cita &&
                              _calculateAgeDetailed(
                                new Date(
                                  cita!.fechaNacimiento.replace("+0000", offset)
                                )
                              )}
                          </Typography>
                          {cita?.motivo !== "" && (
                            <Typography variant="body1" paragraph>
                              Motivo de consulta: {cita?.motivo}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}

                <TextField
                  style={{marginBottom: 10}}
                  multiline
                  spellCheck={true}
                  required={datosObligatorios}
                  label="Subjetivo"
                  name="motivoExtendido"
                  onChange={(e) => setMotivoExtendidoConsulta(e.target.value)}
                  fullWidth
                />
                <TextField
                  style={{marginBottom: 10}}
                  multiline
                  spellCheck={true}
                  required={datosObligatorios}
                  label="Objetivo"
                  name="enfermedadActual"
                  onChange={(e) => {
                    setDescripcionEnfermedadActual(e.target.value);
                  }}
                  fullWidth
                />
                <Autocomplete
                  id="diagnostico-presuntivo"
                  multiple
                  options={enfermedadesCie10}
                  getOptionLabel={(option) => `(${option.ID}) ${option.Name}`}
                  fullWidth
                  value={diagnosticoPresuntivo}
                  //disabled={buscandoCie10}
                  onChange={(event: any, newValue: IEnfermedadCie10[]) => {
                    setDiagnosticoPresuntivo(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={datosObligatorios}
                      label="Evaluación"
                      name="diagnosticoPresuntivo"
                      //disabled={buscandoCie10}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {buscandoCie10 ? (
                              <LoadingBubbles size={30} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      onChange={(e) => {
                        handleChangeDiagnosticoPresuntivo(e.target);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs />
            </Grid>

            <Grid item container xs={12} className={classes.drawer}>
              <Paper
                style={{
                  maxWidth: drawerWidth - 15,
                  marginLeft: 5,
                  marginRight: 5,
                  marginTop: 20,
                }}
                elevation={3}
              >
                <Typography
                  variant="h6"
                  style={{marginLeft: 15, marginTop: 5, marginBottom: 5}}
                >
                  Plan
                </Typography>
                <Divider variant="middle" classes={{root: classes.divider}} />
                <Tabs
                  style={{
                    flexGrow: 1,
                    width: "100%",
                    margin: "0px 15px 0px 15px",
                  }}
                  value={value}
                  onChange={handleChange}
                >
                  <Tab
                    style={styles.tab}
                    {...a11yProps(0)}
                    label="Conducta Terapéutica"
                  />
                  <Tab
                    style={styles.tab}
                    {...a11yProps(1)}
                    label="Notas Privadas"
                  />
                  <Tab style={styles.tab} {...a11yProps(2)} label="Prácticas" />
                  <Tab
                    style={styles.tab}
                    {...a11yProps(3)}
                    label="Medicación"
                  />
                  {hayInterconsultas && (
                    <Tab
                      style={styles.tab}
                      {...a11yProps(4)}
                      label="Interconsultas"
                    />
                  )}
                  {/*} <Tab style={styles.tab} {...a11yProps(4)} label="Derivación" /> */}
                </Tabs>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <div style={Object.assign({}, styles.slide)}>
                    <TextField
                      multiline
                      spellCheck={true}
                      label="Indicaciones"
                      fullWidth
                      style={{flex: 1, width: "100%", flexGrow: 1}}
                      onChange={(e) => setIndicadores(e.target.value)}
                    ></TextField>
                    <p />
                    <TextField
                      multiline
                      spellCheck={true}
                      label="Signos de Alerta"
                      fullWidth
                      style={{flex: 1, width: "100%"}}
                      onChange={(e) => setSignosAlerta(e.target.value)}
                    ></TextField>
                  </div>
                  <div style={Object.assign({}, styles.slide)}>
                    <TextField
                      multiline
                      spellCheck={true}
                      label="Notas"
                      fullWidth
                      style={{flex: 1, width: "100%", flexGrow: 1}}
                      onChange={(e) => setNotasPrivadas(e.target.value)}
                    ></TextField>
                  </div>
                  <div style={Object.assign({}, styles.slide)}>
                    <span>
                      <Button
                        variant="outlined"
                        fullWidth
                        //startIcon={<AddIcon />}
                        style={{marginTop: 10}}
                        onClick={() => {
                          setDialogAmbulatoriaOpen(true);
                        }}
                      >
                        {textoAmbu}
                      </Button>
                    </span>
                    {cita?.especialidad === "ODONTOLOGIA" && (
                      <span>
                        <Button
                          variant="outlined"
                          fullWidth
                          //startIcon={<AddIcon />}
                          style={{marginTop: 10}}
                          onClick={() => {
                            setDialogOdontoOpen(true);
                          }}
                        >
                          {textoOdonto}
                        </Button>
                      </span>
                    )}
                  </div>
                  <div style={Object.assign({}, styles.slide)}>
                    
                    <Typography paragraph align="center">
                      Prescripción de medicamentos de venta libre o bajo receta
                    </Typography>
                    <Grid container direction="column" spacing={1}>
                      {recetasArmadas.map((receta, indice) => {
                        const listadoMedicamentos = receta.medicamentos
                          .filter((medicamento) => medicamento !== null)
                          .map((medicamento) => (
                            <Grid item xs={12}>
                              <Chip
                                style={{maxWidth: "inherit"}}
                                variant="outlined"
                                avatar={
                                  <Avatar>
                                    {medicamento?.cantidadEnvases}
                                  </Avatar>
                                }
                                label={medicamento?.f0_}
                              />
                            </Grid>
                          ));

                        const eliminarReceta = () => {
                          let recetasTemp = [...recetasArmadas];
                          recetasTemp.splice(indice, 1);
                          setRecetasArmadas(recetasTemp);
                        };

                        const editarReceta = () => {
                          setAgregandoRecetaNueva(false);
                          setIndexRecetaEditar(indice);
                          setMedicamentosSeleccionados(receta.medicamentos);
                          setDiagnosticoReceta(receta.diagnosticoReceta);
                          setTratamientoProlongado(
                            receta.tratamientoProlongado.toLowerCase() ===
                              "true"
                          );
                          openDialogEditarReceta();
                        };

                        return (
                          <Grid item xs={12}>
                            <Card style={{flexGrow: 1}} elevation={3}>
                              <CardContent>
                                <Grid item container xs={12} direction="row">
                                  <Grid item container xs={9}>
                                    {listadoMedicamentos}
                                  </Grid>
                                  <Grid item container xs={3}>
                                    <IconButton
                                      color="primary"
                                      onClick={editarReceta}
                                    >
                                      <Edit />
                                    </IconButton>
                                    <IconButton
                                      color="secondary"
                                      onClick={eliminarReceta}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <span>
                      <Button
                        variant="outlined"
                        fullWidth
                        startIcon={<AddIcon />}
                        style={{marginTop: 10}}
                        onClick={() => {
                          setAgregandoRecetaNueva(true);
                          setMedicamentosSeleccionados([null]);
                          setTratamientoProlongado(false);
                          setDiagnosticoReceta(diagnosticoPresuntivo);
                          openDialogEditarReceta();
                        }}
                      >
                        Agregar receta
                      </Button>
                    </span>
                  </div>
                  <div style={Object.assign({}, styles.slide)}>
                    <Typography paragraph align="center">
                      Seleccione la opción para prescribir una interconsulta
                    </Typography>
                    <Grid container direction="column" spacing={1}>
                      {interconsultasArmadas.map((interconsulta, indice) => {
                        const eliminarInterconsulta = () => {
                          let interconsultasTemp = [...interconsultasArmadas];
                          interconsultasTemp.splice(indice, 1);
                          setInterconsultasArmadas(interconsultasTemp);
                        };

                        const editarInterconsulta = () => {
                          setAgregandoInterconsultaNueva(false);
                          setIndexInterconsultaEditar(indice);
                          setResumenInterconsulta(interconsulta.descripcion);
                          setDiagnosticoInterconsulta(interconsulta.evaluacion);
                          setEspecialidadInterconsulta(
                            interconsulta.especialidad
                          );
                          openDialogInterconsulta();
                        };

                        let subheader = interconsulta.descripcion;
                        if (subheader.length > 63) {
                          subheader = subheader.substring(0, 60) + "...";
                        }

                        return (
                          <Grid item xs={12}>
                            <Card elevation={3}>
                              <CardHeader
                                title={interconsulta.especialidad}
                                avatar={<Avatar>{indice + 1}</Avatar>}
                                subheader={subheader}
                                action={
                                  <>
                                    <IconButton
                                      color="primary"
                                      onClick={editarInterconsulta}
                                    >
                                      <Edit />
                                    </IconButton>
                                    <IconButton
                                      color="secondary"
                                      onClick={eliminarInterconsulta}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </>
                                }
                              />
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <span>
                      <Button
                        variant="outlined"
                        fullWidth
                        startIcon={<AddIcon />}
                        style={{marginTop: 10}}
                        disabled={disabledAgregar}
                        onClick={() => {
                          setAgregandoInterconsultaNueva(true);
                          setResumenInterconsulta("");
                          setDiagnosticoInterconsulta(diagnosticoPresuntivo);
                          setEspecialidadInterconsulta("");
                          openDialogInterconsulta();
                        }}
                      >
                        Agregar
                      </Button>
                    </span>
                  </div>
                </SwipeableViews>
              </Paper>
            </Grid>

            <Grid item container xs={12}>
              <Divider />
              {cita && (
                <>
                  {cita!.historialClinico &&
                  cita!.historialClinico.length > 0 ? (
                    <Button
                      fullWidth
                      style={{margin: 5, marginTop: 10}}
                      disableElevation
                      variant="contained"
                      onClick={() => {
                        setDialogHistorialOpen(true);
                      }}
                    >
                      Ver historial
                    </Button>
                  ) : (
                    <Typography>
                      No hay historial disponible para el paciente seleccionado
                    </Typography>
                  )}
                  <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={dialogHistorialOpen}
                    onClose={() => {
                      setDialogHistorialOpen(false);
                    }}
                  >
                    <DialogContent>
                      <Box m={1}>
                        <Box>
                          <Grid container item alignItems="center">
                            <HistoryRounded style={{margin: "7px"}} />
                            <Typography variant="h5">Historial</Typography>
                          </Grid>
                        </Box>
                        <h4>
                          Consultas anteriores:{" "}
                          {cita ? cita!.historialClinico.length : "0"}
                        </h4>
                        {cita
                          ? cita!.historialClinico.map(
                              (elementoHistorial, index) => {
                                if (
                                  index >=
                                    paginaHistorial *
                                      elementosPerPagHistorial &&
                                  index <
                                    (paginaHistorial + 1) *
                                      elementosPerPagHistorial
                                ) {
                                  return (
                                    <>
                                      <Divider key={index + "divider"} />
                                      <CardHistoriaClinica
                                        mostrarNotasPrivadas={true}
                                        key={index + "card"}
                                        elementoHistorial={elementoHistorial}
                                        elevation={0}
                                      />
                                    </>
                                  );
                                }
                                return null;
                              }
                            )
                          : null}
                      </Box>
                      {cita ? (
                        <Grid container justify="center">
                          <Pagination
                            style={{margin: 10}}
                            count={Math.ceil(
                              cita!.historialClinico.length /
                                elementosPerPagHistorial
                            )}
                            page={paginaHistorial + 1}
                            onChange={(event, value) => {
                              setPaginaHistorial(value - 1);
                            }}
                          />
                        </Grid>
                      ) : null}
                    </DialogContent>
                  </Dialog>
                </>
              )}
              <Button
                variant="contained"
                disableElevation
                component="label"
                fullWidth
                onClick={() => {
                  StartEnviarHistoriaClinica();
                }}
                color="primary"
                disabled={
                  datosObligatorios &&
                  (diagnosticoPresuntivo.length === 0 ||
                    !motivoExtendidoConsulta ||
                    !descripcionEnfermedadActual)
                }
              >
                Finalizar atención
              </Button>
              <Button
                variant="contained"
                disableElevation
                color="secondary"
                component="label"
                fullWidth
                onClick={() => {
                  setCancelarCitaOpen(true);
                }}
                style={{...theme.buttons.cancelButton, margin: 5}}
              >
                Cancelar Cita
              </Button>
              <ConfirmationDialog
                title="Cancelar cita?"
                open={cancelarCitaOpen}
                setOpen={setCancelarCitaOpen}
                onConfirm={() => {
                  setLoadingSalir(true);
                  cancelarCita({
                    usuario: cuilPacienteSeleccionado!.toString(),
                    sesion: idSalaVideollamada,
                  })
                    .then((datosRecibidos) => {
                      setLoadingSalir(false);
                      history.push("/dashboard");
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }}
              >
                <Typography>Está seguro que desea cancelar la cita?</Typography>
              </ConfirmationDialog>
              <Button
                variant="contained"
                disableElevation
                color="inherit"
                component="label"
                fullWidth
                onClick={() => {
                  setDevolverEsperaOpen(true);
                }}
                style={{margin: 5}}
              >
                Devolver a Sala de Espera
              </Button>
              <ConfirmationDialog
                title="Devolver a sala de espera?"
                open={devolverEsperaOpen}
                setOpen={setDevolverEsperaOpen}
                onConfirm={() => {
                  setLoadingSalir(true);
                  devolverSalaEspera({
                    usuario: cuilPacienteSeleccionado!.toString(),
                    sesion: idSalaVideollamada,
                  })
                    .then((datosRecibidos) => {
                      setLoadingSalir(false);
                      history.push("/dashboard");
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }}
              >
                <Typography>
                  Está seguro que desea devolver al paciente a la sala de
                  espera?
                </Typography>
              </ConfirmationDialog>
            </Grid>
          </Grid>
        ) : null}
        {estadoRegistro === "enviando" ? (
          <Grid container direction="column" spacing={3}>
            <Grid item container xs={12} alignItems="center" justify="center">
              <p />
              <p />
              <p />
              <p />
              <p />
              <p />
              <p />
              <p />
            </Grid>
            <Grid item container xs={12} alignItems="center" justify="center">
              <LoadingBubbles />
              <Typography variant="h5">Enviando Registro...</Typography>
            </Grid>
          </Grid>
        ) : null}
        {estadoRegistro === "enviado" ? (
          <Grid container direction="column" spacing={3}>
            <Grid item container xs={12} alignItems="center" justify="center">
              <p />
              <p />
              <p />
              <p />
              <p />
              <p />
              <p />
              <p />
            </Grid>
            <Grid item container xs={12} alignItems="center" justify="center">
              <CheckCircleOutline style={{margin: "10px"}} />
              <Typography variant="h5">Enviado!</Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center" justify="center">
              {/*} <PDFDownloadLink style={{ textDecoration: "none" }} document={(
                                    <Document>
                                        <Page size="A4" style={pdfStyles.page}>
                                            <View style={pdfStyles.sectionHeader}>
                                                <Text>MEDIFE</Text>
                                            </View>
                                            <View style={pdfStyles.sectionContent}>
                                                <View style={{ minWidth: "7cm", maxWidth: "7cm" }}>
                                                    <Text style={{ fontWeight: "bold" }}>QR</Text>
                                                </View>
                                                <View>
                                                    <Text>Fecha: {fechaAtencion}</Text>
                                                    <Text />
                                                    <Text>Datos del paciente:</Text>
                                                    <Text>RUBIO, ADRIAN ALBERTO - DNI 30428128</Text>
                                                    <Text>Obra social: Medife</Text>
                                                    <Text>Nro afiliado: 101265165165</Text>
                                                    <Text>Plan: PLATA 450</Text>
                                                    <Text>Código de Validación: 64CCDA8B</Text>
                                                    <Text></Text>
                                                    {
                                                        practicasLabSeleccionadas?.map((practica, index) => {
                                                            return (
                                                                <>
                                                                    <Text key={index}>{practica.descripcion}</Text>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    <Text></Text>
                                                    <Text>Diagnóstico:</Text>
                                                    {
                                                        diagnosticoPresuntivo ? (
                                                            <Text>{diagnosticoPresuntivo.Name}</Text>
                                                        ) : (null)
                                                    }
                                                    <Text></Text>
                                                    <Text>Médico:</Text>
                                                    <Text>{`${nombre} ${apellido}`}</Text>
                                                    <Text>Matrícula: MN 12365</Text>
                                                </View>
                                            </View>
                                        </Page>
                                    </Document>
                                )} fileName="orden_practicas.pdf">
                                    {({ blob, url, loading, error }) => (loading ? 'Cargando orden...' : (<Button variant="outlined" >Descargar Orden</Button>))}
                                                </PDFDownloadLink>*/}
            </Grid>
          </Grid>
        ) : null}
      </Drawer>
      <Dialog
        open={dialogEnviarOpen}
        onClose={handleCloseDialogEnviar}
        aria-labelledby="form-dialog-title"
      >
        <>
          <DialogContent>
            <DialogContentText>
              <Typography paragraph>Desea finalizar la consulta?</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              style={theme.buttons.okButton}
              disableElevation
              onClick={handleEnviarDialogSi}
            >
              Si
            </Button>
            <Button
              variant="contained"
              style={theme.buttons.cancelButton}
              disableElevation
              onClick={handleCloseDialogEnviar}
            >
              No
            </Button>
          </DialogActions>
        </>
      </Dialog>
      {dialogAmbulatoria}
      {dialogOdonto}
      <Dialog open={dialogEditarRecetaOpen} maxWidth="md" fullWidth>
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <Typography variant="h5" style={{flexGrow: 1}}>
              Receta
            </Typography>
            <IconButton onClick={closeDialogEditarReceta}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Autocomplete
            id="diagnostico-receta"
            multiple
            options={enfermedadesCie10}
            getOptionLabel={(option) => `(${option.ID}) ${option.Name}`}
            value={diagnosticoReceta}
            //disabled={buscandoCie10}
            style={{flexGrow: 1, margin: "10px"}}
            onChange={(event: any, newValue: IEnfermedadCie10[]) => {
              setDiagnosticoReceta(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Diagnostico Receta"
                name="diagnosticoReceta"
                //disabled={buscandoCie10}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoCie10 ? <LoadingBubbles size={30} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleChangeDiagnosticoPresuntivo(e.target);
                }}
              />
            )}
          />
          {medicamentosSeleccionados.map((medicamento, indexMedicamento) => {
            return (
              <>
                <Card>
                  <CardContent>
                    <Grid
                      container
                      item
                      xs={12}
                      direction="row"
                      alignItems="center"
                    >
                      <Autocomplete
                        id="Medicamentos"
                        options={medicamentos}
                        getOptionLabel={(option) =>
                          `(${option.numeroRegistro}) ${option.f0_}`
                        }
                        value={
                          medicamento
                            ? {
                                f0_: medicamento!.f0_,
                                numeroRegistro: medicamento!.numeroRegistro,
                              }
                            : null
                        }
                        style={{flexGrow: 1}}
                        onChange={(
                          event: any,
                          newValue: IMedicamentos | null
                        ) => {
                          let medicamentosTemp = [...medicamentosSeleccionados];
                          if (newValue != null) {
                            setMedicamentos(
                              medicamentos.filter(
                                (medic) =>
                                  medic.numeroRegistro !==
                                  newValue.numeroRegistro
                              )
                            );
                            medicamentosTemp[indexMedicamento] = {
                              f0_: newValue!.f0_,
                              numeroRegistro: newValue!.numeroRegistro,
                              indicaciones: medicamento
                                ? medicamento!.indicaciones
                                : "",
                              cantidadEnvases: medicamento
                                ? medicamento!.cantidadEnvases
                                : 1,
                              permiteSustitucion: medicamento
                                ? medicamento!.permiteSustitucion
                                : "N",
                            };
                          } else {
                            medicamentosTemp[indexMedicamento] = null;
                          }
                          setMedicamentosSeleccionados(medicamentosTemp);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Medicamento"
                            name="Medicamentos"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {buscandoMedicamento ? (
                                    <LoadingBubbles size={30} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                            onChange={(e) => {
                              handleChangeMedicamentos(e.target);
                            }}
                          />
                        )}
                      />
                      <TextField
                        style={{marginLeft: 10, width: 120}}
                        label="Cantidad"
                        name="cantidadMedicamentos"
                        type="number"
                        value={medicamento ? medicamento!.cantidadEnvases : ""}
                        disabled={!medicamento}
                        onChange={(event) =>
                          onMedicamentoPropChanged(
                            event as any,
                            indexMedicamento,
                            "cantidadEnvases"
                          )
                        }
                      />
                      {medicamentosSeleccionados.length > 1 ? (
                        <IconButton
                          style={{marginLeft: 10}}
                          onClick={() => {
                            setMedicamentosSeleccionados((prevMedicamentos) => {
                              const medTemp = [...prevMedicamentos];
                              medTemp.splice(indexMedicamento, 1);
                              return medTemp;
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      ) : null}
                    </Grid>
                    <p />
                    <TextField
                      fullWidth
                      multiline
                      spellCheck={true}
                      label="Indicaciones"
                      name="indicacionesMedicamento"
                      value={medicamento ? medicamento!.indicaciones : ""}
                      disabled={!medicamento}
                      onChange={(event) =>
                        onMedicamentoPropChanged(
                          event as any,
                          indexMedicamento,
                          "indicaciones"
                        )
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            onMedicamentoPropChanged(
                              event as any,
                              indexMedicamento,
                              "permiteSustitucion"
                            );
                          }}
                          name="permiteSustitucion"
                          checked={
                            medicamento
                              ? medicamento!.permiteSustitucion.toLowerCase() ===
                                "s"
                              : false
                          }
                          disabled={!medicamento}
                        />
                      }
                      label="Permite Sustitucion"
                    />
                  </CardContent>
                </Card>
                <p />
              </>
            );
          })}
          {medicamentosSeleccionados.length < 2 ? (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              fullWidth
              onClick={() => {
                setMedicamentosSeleccionados((prevMedicamentos) => [
                  ...prevMedicamentos,
                  null,
                ]);
              }}
            >
              Agregar Medicamento
            </Button>
          ) : null}
          <p />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => {
                  setTratamientoProlongado(event.target.checked);
                }}
                name="tratamientoProlongado"
                checked={tratamientoProlongado}
              />
            }
            label="Tratamiento Prolongado"
          />
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox
                  checked={parametrosReceta.duplicado}
                  onClick={() => {
                    const obj = { ...parametrosReceta }
                    obj.duplicado = !parametrosReceta.duplicado
                    setParametrosReceta(obj)
                  }}
                />
              } label="Duplicado Receta" />
              <FormControlLabel control={
                <Checkbox
                  checked={parametrosReceta.generico}
                  onClick={() => {
                    const obj = { ...parametrosReceta }
                    obj.generico = !parametrosReceta.generico
                    setParametrosReceta(obj)
                  }}
                />
              } label="Genérico Receta" />
            </FormGroup>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={isAceptarRecetaDisabled}
            onClick={acceptDialogEditarReceta}
          >
            Aceptar
          </Button>
          <Button
            style={theme.buttons.cancelButton}
            variant="contained"
            disableElevation
            onClick={closeDialogEditarReceta}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogInterconsultaOpen} maxWidth="md" fullWidth>
        <DialogTitle disableTypography>
          <Grid container alignItems="center">
            <Typography variant="h5" style={{flexGrow: 1}}>
              Interconsulta
            </Typography>
            <IconButton onClick={closeDialogInterconsulta}>
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* dialog interconsulta */}
          <FormControl fullWidth style={{margin: "5px 0px 5px 0px"}}>
            <InputLabel htmlFor="especialidad">Interconsulta con</InputLabel>
            <Select
              value={especialidadInterconsulta}
              onChange={(event) => {
                setEspecialidadInterconsulta(event.target.value as string);
              }}
              label="Interconsulta con"
              inputProps={{
                name: "especialidad",
              }}
            >
              {listaEspecialidadesApi.map((especialidad, index) => {
                return (
                  <MenuItem key={index} value={especialidad}>
                    {especialidad}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Autocomplete
            id="diagnostico-receta"
            multiple
            options={enfermedadesCie10}
            getOptionLabel={(option) => `(${option.ID}) ${option.Name}`}
            value={diagnosticoInterconsulta}
            //disabled={buscandoCie10}
            style={{margin: "5px 0px 5px 0px"}}
            fullWidth
            onChange={(event: any, newValue: IEnfermedadCie10[]) => {
              setDiagnosticoInterconsulta(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Evaluación CamDoctor"
                name="evaluacionCamdoctor"
                //disabled={buscandoCie10}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {buscandoCie10 ? <LoadingBubbles size={30} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => {
                  handleChangeDiagnosticoPresuntivo(e.target);
                }}
              />
            )}
          />
          <TextField
            style={{margin: "5px 0px 5px 0px"}}
            fullWidth
            multiline
            rows={3}
            rowsMax={12}
            value={resumenInterconsulta}
            onChange={(event) => {
              setResumenInterconsulta(event.target.value as string);
            }}
            label="Resumen del caso"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={isAceptarInterconsultaDisabled}
            onClick={acceptDialogEditarInterconsulta}
          >
            Aceptar
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={closeDialogInterconsulta}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ConsultorioMedicoProgramadas);
